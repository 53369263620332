.estilo_campos{
    color:white;
}
@font-face{
    font-family: 'Scania Regular';
    src: url('../images/sitio/fuentes/ScaniaSans-Regular.otf');
  }
  @font-face{
    font-family: 'Scania Bold';
    src: url('../images/sitio/fuentes/ScaniaSans-Bold.otf');
  }

.ant-input{
    background-color: #f1f2f2!important;
    border: 0px solid #d9d9d9!important;

}
.ant-input-affix-wrapper{
    background-color: #f1f2f2!important;
    border: 0px solid #d9d9d9!important;
}

.nom-label{
    color:#606060!important;
    font-size: 18px!important;
    font-family: 'Scania Regular'!important;
}
.nom-label-cel{
    color:#538ae4!important;
    font-size: 18px!important;
    font-family: 'Scania Bold'!important;
}
.num-men{
    background-color: #538ae4;
    color:#ffffff;
    font-size: 18px;
    font-family: 'Scania Regular';
}
.ant-form-item-required{
    font-family: 'Scania Regular'!important;
}

@media only screen and (max-width: 500px) {
    .nom-label, .nom-label-cel, .num-men{
        font-size: 12px!important;
    }
    .ant-form-item-required{
        font-size: 12px!important;
    }
}
.transmision{
    width: 50%;
}

.select_telefono{
    width:100px;
}

.boton_recibir_codigo{
    color: white !important;
    background-color:#fe633f !important;
}

.boton_registrate{
    color: #538ae4 !important;
}

.boton_sin_login{
    background-color: #1ed9e6 !important;
    color: white !important;
}
.selector_radio_opcion{
    color:black;
}

.selector_radio_opcion:hover{
    color:#538ae4 !important;
}

.selector_radio_opcion:focus{
    color:#538ae4 !important;
}

