.error_text_grende{
  color: #606060;
  font-family: 'Scania Headlinev1B';
  font-size: 30px;
  margin: 0px;
}
.error_text{
  color: #606060;
  font-family: 'Scania officeB';
  font-size: 14px;
}

@media screen and (max-width:767px){
  .error_text_grende{
    font-size: 24px;
  }
  .error_text{
    font-size: 12px;
  }
}
