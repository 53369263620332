@font-face{
  font-family: 'Scania Headline Bold';
  src: url('../images/sitio/fuentes/ScaniaSansHeadline-Bold.otf');
}
@font-face{
  font-family: 'Scania Regular';
  src: url('../images/sitio/fuentes/ScaniaSans-Regular.otf');
}
@font-face{
  font-family: 'Scania Bold';
  src: url('../images/sitio/fuentes/ScaniaSans-Bold.otf');
}
@font-face{
  font-family: 'Scania Headlinev1R';
  src: url('../images/sitio/fuentes/ScaniaSansHeadlineV1-Regular.otf');
}

@font-face{
  font-family: 'Scania officeR';
  src: url('../images/sitio/fuentes/scania_office/ScaniaOffice-Regular.ttf');
}

@font-face{
  font-family: 'Scania officeB';
  src: url('../images/sitio/fuentes/scania_office/ScaniaOffice-Bold.ttf');
}

.titulo-registrar{
  text-align: center;
  font-size: 52px;
  color: #01356f;
  font-family: 'Scania Headlinev1R';
  letter-spacing: 3px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}
@media only screen and (max-width: 500px) {
  .titulo-registrar{
  font-size: 24px;
  }
}
.titulo-completo{
  text-align: center;
  font-size: 42px;
  color: #01356f;
  font-family: 'Scania Headlinev1R';
  letter-spacing: 3px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.texto-breve{
  color:#414042;
  font-size: 24px;
  text-align: center;
  font-size: 'Scania Regular';
}
.correo-azul{
  color:#538ae4;
  font-size: 24px;
  text-align: center;
  font-size: 'Scania Bold';
}
.texto-azul{
  color:#414042;
  font-size: 22px;
  text-align: center;
  font-size: 'Scania Regular';
}
.datos-azul{
  color:#538ae4;
  font-size: 22px;
  text-align: center;
  font-size: 'Scania Bold';
}
.leyenda{
  color:#538ae4;
  font-size: 16px;
  text-align: center;
  font-size: 'Scania Regular';
}
.fondo{
  background-color: #538ae4;
  color: #ffffff;
  font-size: 24px;
  padding: 5px;
}
.nota{
  color:#6d6f71;
  font-size: 14px;
  text-align: center;
  font-size: 'Scania Regular';
}

.texto_inicio_registro{
  font-size: 25px;
  color: rgb(25,48,122);
  text-align: center;
  font-weight: bold;
  font-family: Scania officeR;
}

@media screen and (max-width: 767px){
  .texto_inicio_registro{
    font-size: 14px;
  }
}

.texto_span_inicio_registro{
  color: #01c7e2;
}

.existe_cuenta_texto{
  text-align: center;
}

.numero_instruccion{
  text-align:right;
  padding-left:0%;
  padding-right:0%;
  font-size: 20px;
}

.texto_instruccion{
  text-align:left;
  padding-left:1%;
  padding-right:0%;
  font-size: 20px;
}

@media screen and (max-width:767px){
  .numero_instruccion .datos-azul, .numero_instruccion{
    font-size: 16px !important;
  }
  
  .texto_instruccion .datos-azul, .texto_instruccion{
    font-size: 16px !important;
  }
}