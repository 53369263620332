@font-face{
  font-family: 'Scania Headlinev1B';
  src: url('../images/sitio/fuentes/ScaniaSansHeadlineV1-Bold.otf');
}
@font-face{
  font-family: 'Scania Bold';
  src: url('../images/sitio/fuentes/ScaniaSans-Bold.otf');
}
@font-face{
  font-family: 'Scania Regular';
  src: url('../images/sitio/fuentes/ScaniaSans-Regular.otf');
}
.video-contenedor{
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}

.contenedor_live{
  background-color: transparent;
}

.cuerpo{
  padding-top: 20px;
  background-image: url('../../assets/images/eventos/euro/fondo_gris_envivo.jpg');
  border-image-repeat: repeat;
  background-size: cover;
  
}

.texto-preguntas{
  font-size: 0.85em;
  text-align: right;
  color: black;
  height: auto;
  line-height: 1;
  border-radius: 10px;
}
.hora-preguntas{
  font-size: 0.7em;
  text-align: right;
  margin: 0% 5%;
  padding: 0% 1% 1% 1%;
}

.ant-form-item{
  margin-bottom: 1%!important;
}
.btn-enviar{
  color: #000000!important;
  background-color: #1ed9e6!important;
  border-radius: 16px!important;
  font-size: 0.8em!important;
  padding: 1px 10px!important;
  border-color: #1ed9e6!important;
}
.ant-form-item-has-error .ant-form-item-explain, .ant-form-item-has-error .ant-form-item-split{
  color:#ffffff!important;
}
.ant-tabs-nav-wrap{
  background-color: #fe633f;
  color:#ffffff;
}
.ant-tabs-tab-btn:focus{
  color:#ffffff!important;
}
.sujeto{
  color: #ffffff;
  font-size: 10px;
  text-align: left;
}

.header-preguntas{
  background-color: #01356f;
  color: #000000;
  font-size: 1.2em;
  padding-top: 0px;

}
.pregunta-p{
  margin: 0px!important;
  color: #ffffff;
  font-family: 'Scania Bold';
}
.ver-preguntas{
  background-color: #ffffff;
  height: 330px;
  overflow: scroll;
}
.area_encuesta{
  background-color: #fe633f;
  color: #ffffff;
  font-size: 2em;
  text-align: left;
  padding: 5px 0px 0px 5px;
}
.area_resultados{
  background-color: #000000;
  color: #ffffff;
  font-size: 2em;
  text-align: left;
  padding: 5px 0px 0px 5px;
}
.text-nom, .text-empre{
  font-size: 0.75em;
  line-height: 1;
}
.preguntas-header{
  background-color: #01356f!important;

}

.contenedor_video {
	position: relative;
	padding-bottom: 56.25%; /*panorámico*/
	padding-top: 0px;
	height: 0;
}
.contenedor_video iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
  height: 100%;
  z-index:99;
}

.contenedor_video img{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
  height: 100%;
  z-index:99;
}

@media screen and (max-width:767px){
  .contenedor_video iframe {
    
  }
  
  .contenedor_video img{
    
  }
}


/*._video2 {
  height: 550px;
}*/
/*AJUSTES*/

.titulo_webinar{
  text-align: left;
  color: #01356f;
  margin-bottom: 0px;
  font-family: 'Scania Regular';
  font-weight: normal;
}

.titulo_webinar_1{
  font-size: 1.5em;
  text-align: left;
  color: #01356f;
  margin-bottom: 0px;
  font-family: 'Scania Regular';
  font-weight: normal;
}

.titulo_webinar_2{
  font-size: 1.5em !important;
  text-align: left;
  color: #01356f;
  margin-bottom: 0px;
  font-family: 'Scania Headlinev1B';
}
.clic_ver{
  color:#01356f;
  text-align: left;
  padding-bottom: 0px;
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: bold;
  position: relative;
  z-index: 99;
}

.clic_ver_red_social{
  cursor:pointer;
  color: #01c7e2;
}

.tipo_preguntas_select{
  color:#ffffff;
  background-color: #fe633f;
  border-top: 3px solid #ffffff;
  padding: 3px 0px 2px 0px;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  font-family: 'Scania Bold';
}
.tipo_preguntas{
  color:#ffffff;
  background-color: #fe633f;
  border: 0px solid #ffffff;
  padding: 3px 0px 2px 0px;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  font-family: 'Scania Bold';
}
.contenedor_enviar{
  background-color: #fe633f;
  padding-top: 10px;
}
.text_enviar{
  margin-left: 0px!important;
}
.sujeto{
  color: #ffffff;
  font-size: 10px;
  text-align: left;
}
.balloon{
  border-radius: 5px;
  background-color: rgb(230,230,230);
  color: black;
  padding: 10px;
  width: 90%;
  margin-left:5%;
  margin-top:20px;
  margin-bottom:20px;
  display: block;
  position:relative;

  font-size: 1em;
  text-align: right;
  height: auto;
  line-height: 1;
  }

  .balloon_respuesta{
    border-radius: 5px;
  background-color: rgb(230,230,230);
  color: black;
  padding: 10px;
  width: 90%;
  margin-left:5%;
  margin-top:20px;
  margin-bottom:20px;
  display: block;
  position:relative;

  font-size: 0.8em;
  text-align: left;
  height: auto;
  line-height: 1;
  }

  .balloon:before {
    content:"";
    width: 0;
    height: 0;
    position: absolute;
    bottom:0px;
    right:-10px;
    border-bottom: 20px solid rgb(230,230,230);
    border-right: 20px solid transparent;
    border-left: 0px solid transparent;
    }

    .balloon_respuesta:before {
      content:"";
      width: 0;
      height: 0;
      position: absolute;
      bottom:0px;
      left:-10px;
      border-bottom: 20px solid rgb(230,230,230);
      border-right: 0px solid transparent;
      border-left: 20px solid transparent;
      }

@media only screen and (min-width: 991px) {
  

  .imagen_logo{
    height:520px;
  }
}
@media only screen and (max-width: 2600px) {
  .ver-preguntas{
    height: 640px;
  }
}
@media only screen and (max-width: 2450px) {
  .ver-preguntas{
    height: 610px;
  }
}
@media only screen and (max-width: 2400px) {
  .ver-preguntas{
    height: 600px;
  }
  .contenedor_live{
    height: 820px;
  }
  .contenedor_live_preguntas{
    height: 825px;
    z-index: 99;
  }
}
@media only screen and (max-width: 2260px) {
  .ver-preguntas{
    height: 580px;
  }
}
@media only screen and (max-width: 2110px) {
  .ver-preguntas{
    height: 550px;
  }
}
@media only screen and (max-width: 2000px) {
  .ver-preguntas{
    height: 490px;
  }
  .contenedor_live{
    height: 750px;
  }
  .contenedor_live_preguntas{
    height: 750px;
  }
}
@media only screen and (max-width: 1780px) {
  .ver-preguntas{
    height: 460px;
  }
}
@media only screen and (max-width: 1700px) {
  .ver-preguntas{
    height: 440px;
  }
  .contenedor_live{
    height: 700px;
  }
  .contenedor_live_preguntas{
    height: 700px;
  }
}
@media only screen and (max-width: 1630px) {
  .ver-preguntas{
    height: 420px;
  }
}
@media only screen and (max-width: 1550px) {
  .ver-preguntas{
    height: 340px;
  }
  .contenedor_live{
    height: 570px;
  }
  .contenedor_live_preguntas{
    height: 570px;
  }
}
@media only screen and (max-width: 1470px) {
  .ver-preguntas{
    height: 325px;
  }
}
@media only screen and (max-width: 1441px) {
  .ver-preguntas{
    height: 310px;
  }
  .contenedor_live{
    height: 550px;
  }
  .contenedor_live_preguntas{
    height: 550px;
  }
}
@media only screen and (max-width: 1361px) {
  .ver-preguntas{
    height: 290px;
  }
  .contenedor_live{
    height: 520px;
  }
  .contenedor_live_preguntas{
    height: 540px;
  }
}
@media only screen and (max-width: 1300px) {
  .ver-preguntas{
    height: 260px;
  }
  .contenedor_live{
    height: 520px;
  }
  .contenedor_live_preguntas{
    height: 500px;
  }
}
@media only screen and (max-width: 1250px) {
  .ver-preguntas{
    height: 250px;
  }
  .contenedor_live{
    height: 500px;
  }
  .contenedor_live_preguntas{
    height: 480px;
  }
}
@media only screen and (max-width: 1180px) {
  .ver-preguntas{
    height: 230px;
  }
}
@media only screen and (max-width: 1161px) {
  .ver-preguntas{
    height: 210px;
  }
  .contenedor_live{
    height: 480px;
  }
  .contenedor_live_preguntas{
    height: 450px;
  }
  .header-preguntas{
    font-size: 1.4em;
  }
  .tipo_preguntas_select{
    font-size: 10px;
  }
  .tipo_preguntas{
    font-size: 10px;
  }
}
@media only screen and (max-width: 1090px) {
  .ver-preguntas{
    height: 190px;
  }
  .contenedor_live{
    height: 450px;
  }
  .contenedor_live_preguntas{
    height: 420px;
  }
  .header-preguntas{
    font-size: 1.2em;
  }
  .btn-enviar{
    font-size: 0.7em!important;
    padding: 2px 7px 2px 7px!important;
  }
  .contenedor_aniversario, .contenedor_graficas{
    height: 280px;
  }
}

@media only screen and (max-width: 991px) {
  .video{
    height: 400px;
  }
  

  .imagen_logo{
    height:350px;
  }
  .ver-preguntas{
    height: 250px;
  }
  .contenedor_live{
    height: 550px;
  }
  .contenedor_live_preguntas{
    height: 440px;
  }
  .btn-enviar{
    font-size: 0.8em!important;
    padding: 2px 10px 2px 10px!important;
    margin-bottom: 5px;
  }
  .tipo_preguntas_select{
    font-size: 12px;
  }
  .tipo_preguntas{
    font-size: 12px;
  }
  .contenedor_aniversario{
    height: 320px;
  }
  .contenedor_graficas{
    height: 260px;
  }
}
@media only screen and (max-width: 900px) {

  .contenedor_live{
    height: 490px;
  }
  .contenedor_live_preguntas{
    height: 440px;
  }
}
@media only screen and (max-width: 850px) {
  .contenedor_live{
    height: 470px;
  }
  .contenedor_live_preguntas{
    height: 440px;
  }
  .header-preguntas{
    font-size: 1.2em;
  }
}
@media only screen and (max-width: 800px) {
  .contenedor_live{
    height: 465px;
  }
  .contenedor_live_preguntas{
    height: 440px;
  }
}
@media only screen and (max-width: 750px) {
  .ver-preguntas{
    height: 250px;
  }
  .contenedor_live{
    height: 430px;
  }
  .contenedor_live_preguntas{
    height: 430px;
  }
  .header-preguntas{
    font-size: 1.2em;
  }
}
@media only screen and (max-width: 700px) {
  .contenedor_aniversario{
    height: 300px;
  }
  .contenedor_graficas{
    height: 240px;
  }
  .contenedor_live{
    height: 430px;
  }
  .contenedor_live_preguntas{
    height: 430px;
  }
}
@media only screen and (max-width: 650px) {
  .contenedor_live{
    height: 410px;
  }
  .contenedor_aniversario{
    height: 300px;
  }
  .contenedor_graficas{
    height: 240px;
  }
}
@media only screen and (max-width: 600px) {
  .video{
    height: 300px;
  }

  .imagen_logo{
    height:250px;
  }

  .contenedor_live{
    height: 390px;
  }
  .contenedor_live_preguntas{
    height: 430px;
  }
  .contenedor_aniversario{
    height: 280px;
  }
  .contenedor_graficas{
    height: 240px;
  }
}
@media only screen and (max-width: 550px) {
  .contenedor_live{
    height: 380px;
  }
  .contenedor_aniversario{
    height: 260px;
  }
}
@media only screen and (max-width: 500px) {
  .contenedor_live{
    height: 290px;
  }
  .titulo_webinar{
    font-size: 0.6em;
    text-align: left;
  }
}
@media only screen and (max-width: 450px) {
  .contenedor_live{
    height: 250px;
  }
  .titulo_webinar{
    font-size: 1em;
    text-align: left;
  }
  .clic_ver{
    font-size: 10px;
  }
  .contenedor_aniversario{
    height: 230px;
  }
}
@media only screen and (max-width: 400px) {
  .contenedor_live{
    height: 230px;
  }
  .contenedor_live_preguntas{
    height: 390px;
  }
  .ver-preguntas{
    height: 200px;
  }
  .contenedor_aniversario{
    height: 200px;
  }
}
@media screen and (max-width: 700px) {
  .nombre_tab_menu{
    font-size: 18px;
  }
}

@media screen and (min-width: 701px) and (max-width: 1000px){
  .nombre_tab_menu{
    font-size: 14px;
  }
}

@media screen and (min-width: 1001px) {
  .nombre_tab_menu{
    font-size: 12px;
  }
}