@font-face{
  font-family: 'Scania Regular';
  src: url('../images/sitio/fuentes/ScaniaSans-Regular.otf');
}
@font-face{
  font-family: 'Scania Bold';
  src: url('../images/sitio/fuentes/ScaniaSans-Bold.otf');
}
@font-face{
  font-family: 'Scania Bolder';
  src: url('../images/sitio/fuentes/ScaniaSansHeadlineV1-Bold.otf');
}
.footer{
  background-color: #01356f;
}
.pie-link{
  font-size:26px;
  color:#ffffff;
  text-align: left;
  font-family: "Scania Bold";
  margin: 0px!important;
}
.pie-peq{
  font-size:10px;
  color:#ffffff;
  text-align: justify;
  font-family: "Scania Regular";
  line-height: 1.4;
}
.text-blanco{
  color: #ffffff;
  text-align: left;
}
.text-azul{
  color: #0d3159;
  text-align: left;
}
.tituLogos{
  border-bottom: 4px solid #000000;
  font-size: 2em;
  text-align: left;
  color: #000000;
  padding-top: 50px;
}
.redes{
  color: #eb4b1c;
  width: 55%;
}

.link-footer{
  color: #538ae4;
  text-decoration: none;
  text-align: left;
  font-family: 'Scania Bolder';
  font-weight: bolder;
  letter-spacing: 1.5px;
  font-size: 17px;
}
.img_face{
  width: 30px;
}
.titu-footer{
  color: #eb4b1c;
  font-size: 2.5em;
  text-align: left;
  font-family: 'Economica', sans-serif;
  margin-bottom: 10px;;
}
@media only screen and (max-width: 500px) {
  .titulo-registrar{
  font-size: 24px;
  }
  .pie-link{
    font-size:22px;
  }
  .link-footer{
    letter-spacing: 1px;
    font-size: 10px;
  }
  .img_face{
    width: 15px;
  }
}