@font-face{
  font-family: 'Scania Regular';
  src: url('../images/sitio/fuentes/ScaniaSans-Regular.otf');
}
@font-face{
  font-family: 'Scania Bold';
  src: url('../images/sitio/fuentes/ScaniaSans-Bold.otf');
}
@font-face{
  font-family: 'Scania Headlinev1R';
  src: url('../images/sitio/fuentes/ScaniaSansHeadlineV1-Regular.otf');
}
@font-face{
  font-family: 'Scania Headlinev1B';
  src: url('../images/sitio/fuentes/ScaniaSansHeadlineV1-Bold.otf');
}
@font-face{
  font-family: 'Scania officeR';
  src: url('../images/sitio/fuentes/scania_office/ScaniaOffice-Regular.ttf');
}

@font-face{
  font-family: 'Scania officeB';
  src: url('../images/sitio/fuentes/scania_office/ScaniaOffice-Bold.ttf');
}

.slick-dots li button:before{
  font-size: 10px!important;
  color: #fe633f!important;
}
.contenedor_seccion_menu{
  position:relative;
  display: inline;
  cursor: pointer;
}
.contenedor_submenu_row{
  position:absolute;
  z-index: 1;
  background-color: #ffffff;
  color:#fe633f;
  width: 500px;
  text-align: left;
  padding: 7px;
  text-transform: uppercase;
  font-family: "Scania Headlinev1R";
}
.contenedor_submenu{
  z-index: 99;
  margin: 0px;
}
.link_submenu{
  color:#000000;
  font-size: 0.9em;
  font-family: "Scania Headlinev1R";
}
.link_submenu:hover{
  color:#fe633f;
}
.link_sub_movil{
  text-align: center;
  font-family: "Scania Headlinev1R";
  font-size: 10px;
  color: #000000;
}
/* For demo */

.titles{
  text-decoration: none;
  color: #000000;
  font-weight: bold;
  font-size: 12px;
}

.img_logo_scania_{
  width:40%;
}
.btn_agenda{
  width: 70%;
}
@media screen and (max-width:767px){
  .img_logo_scania_{
    width:75%;
  }
}

.titles-redes{
  text-decoration: none;
  color: #808285;
  font-size: 12px;
}
.principal{
  background-image: url('../images/eventos/lanzamiento-camion-scania-6/recordatorio_PORcomenzar.jpg');
  background-repeat: no-repeat;
  background-size: contain;
  height: 630px;
}
.principal_uno{
  background-image: url('../../assets/images/eventos/venta_postventa/banner_inico.jpg');
  background-size: 100% 100%;
  background-position: center center;
  padding-top: 34%;
  padding-bottom:4% ;
  text-align: center;
  height: cover;
}
.principal_uno_movil{
  background-image: url('../../assets/images/eventos/venta_postventa/banner_inico.jpg');
  background-size: 100% 100%;
  background-position: center center;
  padding-top: 15%;
  padding-bottom:15%;
  text-align: center;
  height: cover;
}
.principal_dos{
  background-image: url('../images/eventos/lanzamiento-camion-scania-6/bannerprincipal_euro6_.jpg');
  background-size: 100% 100%;
  background-position: center center;
  padding-top: 36%;
  padding-bottom:3% ;
  text-align: center;
  height: cover;
}

.principal_dos_movil{
  background-image: url('../images/eventos/lanzamiento-camion-scania-6/bannerPrincipal_movil.jpg');
  background-size: 100% 100%;
  background-position: center center;
  padding-top: 50%;
  padding-bottom:90%;
  text-align: center;
  height: cover;
}

.banner_principal{
  width: 100%;
}

.contenedor_registro_banners{
  position: absolute !important;
  width: 100%;
  z-index:9999 !important;
  left:72%;
  bottom:18%;
}

.imagen_boton_registrate{
  width: 25%;
}

.espacio{
  height: 370px;
}
.titulo{
  color: #ffffff;
  font-size: 45px;
  line-height: 1.4;
  font-weight: bold;
  font-family:  'Bebas Neue';
}
.fecha-top{
  color: #f4b414;
  font-size: 40px;
  line-height: 1;
  font-family:  'Bebas Neue';
}
.mensaje-contador{
  font-size: 40px;
  font-family: 'Scania Headlinev1B';
  font-weight: bold;
  padding: 0px!important;
  color: #fe633f;
}
.contenedor_contador{

}
figure.containImageLiveANTP {
  width: 100%;
  text-align: center;
  overflow: hidden;
  /*hide bounds of image*/
  margin: 0;
  /*reset margin of figure tag*/
}


figure.containImageLiveANTP img {
  display: block;
  /*remove inline-block spaces*/
  width: 100%;
  /*make image streatch*/
  margin: -10% 0;
}

@media screen and (max-width: 575px) {
  .contenedor_contador{
    margin-top:25px !important;
  }
}



.numero{
  color: #fe633f;
  font-size: 70px;
  font-family: 'Scania Headlinev1B';
}
.fecha{
  font-family: 'Scania Regular';
  color: #333333;
  font-size: 21px;
}
.contador{
  line-height: 1;
  margin-bottom: 0.6em;
}
.contadorf{
  line-height: 1;
}
.subtitle{
  color: #ffffff;
  font-size: 24px;
  line-height: 1;
  margin-top: 3%;
}
.ponente{
  width: 100%;
}
.programa{
  background-image: url('../images/sitio//home/fondo_seccwebinars.jpg');
  border-image-repeat: no-repeat;
  background-size: cover;
}
.divPrograma{
  background-color: #ffffff;
  text-align: left;
  line-height: 1;
}
.tituPrograma{
  color:#ffffff;
  text-align: center;
  margin: 3% 0%;
  font-size: 3em;
  font-family: 'Scania Headlinev1R';
}
.visualizar{
  border-bottom: 1px solid #000000;
}
.info-hora{
  color:#000000;
  font-size: 1.3em;
  font-family: 'Economica', sans-serif;
  font-weight: bold;
}
.info-anio{
  color:#eb4b1c;
  font-size: 2em;
  font-family: 'Scania Headlinev1R';
}
.info-fecha{
  color:rgb(25,48,122);
  font-size: 0.9em;
  font-family: 'Scania Headlinev1B';
}
.info-nombre{
  color:#5c6069;
  font-size: 21px;
  font-family: 'Scania officeR';

}
/*font-family: 'Montserrat', sans-serif;*/
.info-descripcion{
  color:#01c7e2;
  font-size: 0.8em;
  /*font-family: 'Montserrat', sans-serif;*/
}
.info-video{
  color:#000000;
}
.info-horario{
  margin: 15px;
}
.divEntradas{
  text-align: right;
  line-height: 1;
}
.tituEntradas{
  color:#eb4b1c;
  margin: 3% 0% 0% 0%;
  font-size: 3em;
}
.textEntradas{
  color:#000000;
  font-size: 1.5em;
}
.tituLogos{
  border-bottom: 4px solid #000000;
  font-size: 3em;
  text-align: left;
  color: #000000;
}

.slick-prev, .slick-next{
  display: none!important;
}
.btn-registrar{
  width: 200px;
}

@media only screen and (max-width: 2700px) {
  .principal{
    height: 1200px!important;
  }
  .espacio{
    height: 700px;
  }
  .numero{
    font-size: 90px;
  }
  .fecha{
    font-size: 31px;
  }
  .btn-registrar{
    width: 500px;
  }

}
@media only screen and (max-width: 1700px) {
  .principal{
    height: 750px!important;
  }
  .espacio{
    height: 440px;
  }
  .numero{
    font-size: 64px;
  }
  .fecha{
    font-size: 21px;
  }
  .btn-registrar{
    width: 250px;
  }
  .web-pasado{
    width: 50%;
  }

  .contenedor_registro_banners{
    position: absolute !important;
    width: 100%;
    z-index:9999 !important;
    left:72%;
    bottom:18%;
  }

  .imagen_boton_registrate{
    width: 25%;
  }
}

@media only screen and (max-width: 1600px) {
  .principal{
    height: 700px!important;
  }
  .espacio{
    height: 400px;
  }
  .numero{
    font-size: 64px;
  }
  .fecha{
    font-size: 21px;
  }
  .btn-registrar{
    width: 250px;
  }
  .web-pasado{
    width: 50%;
  }

  .contenedor_registro_banners{
    position: absolute !important;
    width: 100%;
    z-index:9999 !important;
    left:72%;
    bottom:18%;
  }

  .imagen_boton_registrate{
    width: 25%;
  }
}

@media only screen and (max-width: 1200px) {
  .principal{
    height: 500px!important;
  }
  .espacio{
    height: 280px;
  }
  .numero{
    font-size: 40px;
  }
  .fecha{
    font-size: 14px;
  }
  .btn-registrar{
    width: 200px;
  }
}
@media only screen and (max-width: 1300px) {
  .principal{
    height: 570px!important;
  }
  .espacio{
    height: 350px;
  }
  .numero{
    font-size: 40px;
  }
  .fecha{
    font-size: 14px;
  }
  .btn-registrar{
    width: 200px;
  }

  .contenedor_registro_banners{
    position: absolute !important;
    width: 100%;
    z-index:9999 !important;
    left:72%;
    bottom:18%;
  }

  .imagen_boton_registrate{
    width: 25%;
  }
}
@media only screen and (max-width: 1100px) {

  .espacio{
    height: 300px;
  }
  .numero{
    font-size: 40px;
  }
  .fecha{
    font-size: 14px;
  }
  .btn-registrar{
    width: 200px;
  }
  .mensaje-contador{
    font-size: 25px;
  }

  .contenedor_registro_banners{
    position: absolute !important;
    width: 100%;
    z-index:9999 !important;
    left:72%;
    bottom:18%;
  }

  .imagen_boton_registrate{
    width: 25%;
  }
}
@media only screen and (max-width: 990px) {
  .principal{
    height: 510px!important;
  }
  .espacio{
    height: 260px;
  }
  .btn_agenda{
    width: 50%;
  }
  
  .contenedor_registro_banners{
    position: absolute !important;
    width: 100%;
    z-index:9999 !important;
    left:72%;
    bottom:18%;
  }

  .imagen_boton_registrate{
    width: 25%;
  }
}
@media only screen and (max-width: 890px) {
  .principal{
    height: 380px!important;
  }
  .espacio{
    height: 210px;
  }
  .numero{
    font-size: 30px;
  }
  .fecha{
    font-size: 10px;
  }
  .btn-registrar{
    width: 150px;
  }
  .info-hora{
    font-size: 1.3em;
  }
  .info-fecha{
    font-size: 1.3em;
  }
  .info-nombre{
    font-size: 1.1em;
  }
  .info-descripcion{
    font-size: 0.8em;
  }
  .mensaje-contador{
    font-size: 20px;
  }
  .link_submenu{
    font-size: 0.6em;
  }
  .contenedor_submenu_row{
    width: 300px;
  }
}
@media only screen and (max-width: 700px) {
  .principal{
    height: 320px!important;
  }
  .espacio{
    height: 200px;
  }
  .fecha{
    font-size: 15px;
  }
  .numero{
    font-size: 22px;
  }
  .btn-registrar{
    width: 100px;
  }
  .info-hora{
    font-size: 1.1em;
  }
  .info-fecha{
    font-size: 1.1em;
  }
  .info-nombre{
    font-size: 0.9em;
  }
  .info-descripcion{
    font-size: 0.8em;
  }
}
@media only screen and (max-width: 550px) {

  .espacio{
    height: 150px;
  }
  .numero{
    font-size: 18px;
  }
  .fecha{
    font-size: 10px;
  }
  .sec-titulo{
  padding-top: 30px;
  }
}
@media only screen and (max-width: 500px) {
  .principal{
    height: 230px!important;
  }
  .espacio{
    height: 110px;
  }
  .numero{
    font-size: 20px;
  }
  .fecha{
    font-size: 8px;
  }
  .web-pasado{
    width: 100%;
  }
  .info-completa{
    margin-bottom: 0px!important;
    padding-top: 0%;
  }
  .mensaje-contador{
    font-size: 12px;
  }
}
@media only screen and (max-width: 450px) {
  .espacio{
    height: 100px;
  }
}
@media only screen and (max-width: 420px) {
  .espacio{
    height: 95px;
  }
}
@media only screen and (max-width: 400px) {
  .principal{
    height: 200px!important;
  }
  .espacio{
    height: 90px;
  }
  .numero{
    font-size: 16px;
  }
  .fecha{
    font-size: 6px;
  }
  .contador{
    margin-bottom: 0.2em;
  }
}
@media only screen and (max-width: 380px) {
  .espacio{
    height: 75px;
  }
  .principal{
    height: 160px!important;
  }
}

.seccion{
  padding: 10px;
  font-family: "Scania Headlinev1R";
  color:#17213b;
}

.seccion_sel{
  padding: 10px;
  font-family: "Scania Headlinev1R";
  color:#17213b;
  border-bottom:1px solid red !important;
  font-weight: bold !important;
}

.seccion-redes{
  padding: 15px;
  border-right: 2 solid #808285;
}
.subir{
  margin-bottom: 20px;
}
.titulo-ponentes{
  color: #01356f;
  font-size: 3em;
  text-align: center;
  font-family: "Scania Headlinev1R";
  margin: 20px 0px!important;
}

/* VISTA DE PONENTES */
.head-ponentes{
  background-color: #538ae4;

}
.head-ponentes-text{
  color:#ffffff;
  font-family: "Scania Headlinev1R";
  font-size: 51px;
  margin: 15px 0px!important;
}
.nombre-ponente{
  font-family: 'Scania officeB';
  color:#01356f;
  font-size: 50px;

}
.titu-ponente{
  font-family: 'Scania Regular';
  color:#01356f;
  font-size: 30px;
}

.desc-ponente{
  font-family: 'Scania Regular';
  color:#01356f;
  font-size: 14px;
}
.contenedor_boton_registrate{
  
}

.img_registrate_banner{
  width:250px;
  margin-top:20%;
}
.img_registrate_banner_movil{
  width:250px;
}

.img_registrate_banner_2_movil{
  width:250px;
  margin-top: 10%;
}

.img_registrate_banner_dos{
  width:215px;
  margin: 0px;
  padding: 0px;
}

@media screen and (min-width:301px) and (max-width:400px){
  .img_registrate_banner{
    width:80px;
  }
  .img_registrate_banner_dos{
    width:65px;
  
  }
}
@media screen and (min-width:401px) and  (max-width:500px){
  .img_registrate_banner{
    width:100px;
  }
  .img_registrate_banner_dos{
    width:90px;
  }
  
}

@media screen and (min-width:501px) and (max-width:769px){
  .img_registrate_banner{
    width:155px;
  }
  .img_registrate_banner_dos{
    width:105px;
  }
  
  
}
@media only screen and (max-width: 910px){
  .img_registrate_banner{
    width:125px;
  }
  .img_registrate_banner_dos{
    width:105px;
  }
  .img_registrate_banner_movil{
    width:200px;
  }
  .img_registrate_banner_2_movil{
    width:200px;
  }

  .contenedor_registro_banners{
    position: absolute !important;
    width: 100%;
    z-index:9999 !important;
    left:72%;
    bottom:18%;
  }

  .imagen_boton_registrate{
    width: 25%;
  }

}
@media only screen and (max-width: 550px){
  .img_registrate_banner_movil{
    width:150px;
  }

  .img_registrate_banner_2_movil{
    width:200px;
  }

  .contenedor_registro_banners{
    position: absolute !important;
    width: 100%;
    z-index:9999 !important;
    left:72%;
    bottom:18%;
  }

  .imagen_boton_registrate{
    width: 25%;
  }
  
}