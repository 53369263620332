@font-face{
  font-family: 'Scania Regular';
  src: url('../images/sitio/fuentes/ScaniaSans-Regular.otf');
}
@font-face{
  font-family: 'Scania Bold';
  src: url('../images/sitio/fuentes/ScaniaSans-Bold.otf');
}
@font-face{
  font-family: 'Scania Headlinev1R';
  src: url('../images/sitio/fuentes/ScaniaSansHeadlineV1-Regular.otf');
}
@font-face{
  font-family: 'Scania Headlinev1B';
  src: url('../images/sitio/fuentes/ScaniaSansHeadlineV1-Bold.otf');
}
@font-face{
  font-family: 'Scania officeR';
  src: url('../images/sitio/fuentes/scania_office/ScaniaOffice-Regular.ttf');
}

@font-face{
  font-family: 'Scania officeB';
  src: url('../images/sitio/fuentes/scania_office/ScaniaOffice-Bold.ttf');
}

.contenedor_programas{
    padding-top: 20px;
    background-color: white;
    padding-bottom: 20px;
}

.contenedor_conferencias{
    background-image: url('../images/sitio//home/fondo_seccwebinars.jpg');
    border-image-repeat: no-repeat;
    background-size: cover;
  }

  .contenedor_video_conferencia {
	position: relative;
	padding-bottom: 56.25%; /*panorámico*/
	padding-top: 0px;
	height: 0;
}
.contenedor_video_conferencia iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.info-horario_conf{
    margin: 15px;
  }

  .info-fecha_conf{
    font-size: 1.1em;
    color: rgb(25,48,122);
    font-weight: bold;
  }

  .info-anio_conf{
    color:#eb4b1c;
    font-size: 2em;
    font-family: 'Scania Headlinev1R';
  }

  .sec-titulo_conf{
    padding-top: 0px;
    }

    .info-completa_conf{
        margin-bottom: 0px!important;
        padding-top: 0px !important;
        text-align: left;
      }

      .info-nombre_conf{
        color:#5c6069;
        font-size: 21px;
        font-family: 'Scania officeR';
      }
      .info-desc_conf{
        color:#5c6069;
        font-size: 14px;
        font-family: 'Scania officeR';
        text-align: justify;
        line-height: 1;
        padding-right: 15px;
      }

      @media screen and (max-width:700px){
        .info-nombre_conf{
          color:#5c6069;
          font-size: 18px;
          font-family: 'Scania officeR';
        }
      }

      .web-pasado_conf{
        width: 50%;
      }

      .info-video_conf{
        color:#000000;
      }

      .info-completa_conf{
        margin-bottom: 0px!important;
        padding-top: 0%;
      }

      .palabra_inicio_seccion{
        font-family: Scania Headlinev1R;
        color:white; 
        font-size:40px;
      }

      .texto_evento_inicio{
        font-family: Scania officeB;
        font-size: 25px;
        color: rgb(25,48,122);
      }

      .texto_resaltado_evebto_inicio{
        color: #01c7e2;
        font-weight: bold;
        font-family: Scania officeB;
      }

      @media screen and (max-width: 767px){
        .texto_evento_inicio{
          font-size: 14px;
        }
      }